@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html, body, html * {
    font-family: 'Roboto', sans-serif;
}

/*h2 {*/
/*   @apply font-bold text-2xl;*/
/*}*/
body {
    color: black;
}

@layer components {
    .divGradient {
        @apply bg-gradient-to-tr from-black/60 via-black/40 to-black/10;
    }
    h1{
        @apply text-center lg:text-start md:text-7xl sm:text-4xl text-2xl lg:space-y-4 font-bold font-sans text-white;
    }
}

.content {
    max-width: 1440px;
    margin: auto;
}

@media screen and (min-width: 1536px) {
    .content {
        padding-left: 160px;
        padding-right: 160px;
    }
}

.divSize {
    height: 1000px;
}


html,
body,
#root {
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

/*.fade-in-element {*/
/*    opacity: 0;*/
/*    transition: opacity 5s ease-in-out;*/
/*}*/

/*.fade-in-element.visible {*/
/*    opacity: 1;*/
/*}*/

.fade-in-out-element {
    transition: opacity 2s ease-in-out;
}




